import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Tasker - Add a Profile to activate Motion Detection",
  "path": "/Advanced_User/Tasker/Motion_Detection_Add_Profile/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Use a macro tool to automate functions of your camera",
  "image": "../AU_SearchThumb_Tasker.png",
  "social": "/images/Search/AU_SearchThumb_Tasker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Tasker_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Tasker - Add a Profile to activate Motion Detection' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a macro tool to automate functions of your camera' image='/images/Search/AU_SearchThumb_Tasker.png' twitter='/images/Search/AU_SearchThumb_Tasker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Tasker/' locationFR='/fr/Advanced_User/Tasker/' crumbLabel="Tasker" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "tasker---add-a-profile-to-activate-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tasker---add-a-profile-to-activate-motion-detection",
        "aria-label": "tasker   add a profile to activate motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tasker - Add a Profile to activate Motion Detection`}</h1>
    <h2 {...{
      "id": "how-do-you-activate-motion-detection-when-you-are-at-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-do-you-activate-motion-detection-when-you-are-at-home",
        "aria-label": "how do you activate motion detection when you are at home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do you activate motion detection when you are at home?`}</h2>
    <h4 {...{
      "id": "hinzufügen-eines-profils-bewegungserkennung-aktiviert",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#hinzuf%C3%BCgen-eines-profils-bewegungserkennung-aktiviert",
        "aria-label": "hinzufügen eines profils bewegungserkennung aktiviert permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen eines Profils (Bewegungserkennung aktiviert)`}</h4>
    <p>{`Now we just have to automate the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Tasker/Motion_Detection_Add_Task/"
      }}>{`just created action`}</a>{`. Go to the profile tab and press the `}<strong parentName="p">{`plus`}</strong>{` to add a new profile. Here we choose the `}<strong parentName="p">{`state preset`}</strong>{` to use the WLAN connection status of the smartphone to trigger the action.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ec1b3ef5d2c530619efd754abfeec207/772e8/Tasker-(8).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "158.50000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAADVklEQVQ4y62Ue2+jRhTF+f79ClX3r2i1tZRG2lWUSqlXcrGJeWMMhmEAB4x5zgwvO8SpSWuzsRwSqZHan44QM+Lo3HthoKDtaHMZGKrtuPbHgBACAGzbpgxgC19/Urk/nKVvQ/ivTsdxTNPked51XcoCQJvJhgmsDwMAME3TsiyKF0RekqcsO+U4lucPS0EURVEQBO6IKP5YdleWZU+blC4JAkNf/fr56svn379eLWRR4bnOeXl5ORgMOO7HUpKk4XB4cXExGAxYlj2YP03mB43nv9DqJ1r9eTL/7U4ReY7jeY7juhz+eM+/7LDsoUSe56lltTvqsbtxq11UPz5sPwT1/B/om9u2/aCzbdv/KbnLRAhBCF3X7b6HJEnKsszzvCiK8oXiSF3X/WTP8zRNc11X07T5fJ6maVmWGGNyRn4EIUT12siyzHVdCKHjOLZtAwCWy2We550NY1zX9WKxAAAURdFPTpJkPB5fX19zHEfT9N3dnaZpPbOiKLqu981dctM0Dw8PXXtVVRVFgV/TlZ2maT85DENJkgzDOH/6vOGiKNQj7/QchiHHcQzDIITIexRFIctyN8tX5v1+H8dx0zRPT09VVeH3QAh1ryqO437Zvu93Z+h8TicwxpvNZjQaMQxDCOmXHQSBrussyy4Wi7cNY4zLsrRt+/7+/p2yoyhqmma32zVNU1VV9zH0KieEIITCMOwnr3xfEgR2Op3Q9Jim75fLw6tCiGDcKSdkrqoQgKhv3u+DKDJdVwNAtyzdstZpSuoalSWuqpO89RrXdfhqYG27f36OFWU3m/0FwKOub1W1lGUiCLkonlRIUi6KmGEiy6J6RzkYjf789u3m5kZTlBwhnGXkTDhNqzwffv8+Hw6RovSTI5b1b2/B7W0wmZSqWsxmPZWzWTCdZjQda9rrabdtuF6XabolpMoyHEXvKI6zIMjCMIoiqvdrWa1WHM9PGMYEYLPdFsfDca56szEAsCBMk4Rqj+z3++7qeR6EUNM00zRXq5X3Bt/3AQCO4/i+T4VhuF6vsyxLkiTLMgih/EL3rxbfIMvyeDw2DINyXZdlWd/3kySJ4zhN0+wIIWSxWOi6TgjJsgydgTHuLFQcx7Ztn8zRGUEQrFar3maH53n/DOxvYOXIEtZ2NdEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec1b3ef5d2c530619efd754abfeec207/f13a9/Tasker-(8).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ec1b3ef5d2c530619efd754abfeec207/ba381/Tasker-(8).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec1b3ef5d2c530619efd754abfeec207/772e8/Tasker-(8).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ec1b3ef5d2c530619efd754abfeec207/772e8/Tasker-(8).png",
              "alt": "Tasker - Add a Profile",
              "title": "Tasker - Add a Profile",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Go to the Profile tab and click the + to add a state profile.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bc24ef14e49e14175001967c15e5f3c2/772e8/Tasker-(9).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEKklEQVQ4y8WUSXPiRhTH9bXyBYzn4MrExmiwqzL4EufsuSX4OB9irq65ZXJAbJNcXJXYgDfCYhMMYjFGoAVL6u7XrQVQp0BjZqaSqXIlh/xKh6en939bd0kQxRfb0W9i25vbsZi4JBqNxmKxF58Tj8efP3++vb0dj8dFUQwDhM2t6Nb6V1tfP4tEnq2tra2vr0eWiKL4qVgUxUgksrGxsbOzE4vFNjc34/G4EGZaZI7HwwpfIhqNiqL4qUfY29v79mkkEomXL1+uXvf29oRXr14dHh7+kEz++Lcn+U+ekMPDw4ODAyGbzQazqYORB8QhmBLsAlna5KMNxAXCAIfYtj2dTjOZjPDL+7zuzkuKXdGw/EBuJ+QPDdd00n4grQe4NkjdWNoTMrCA0QWEkCAIsrmc8Gs+Z3uzC8Uqq6ih44GJqyq6GKP2BPdNXFZRRUUFxa6oqGVgn4FDgRHMg3k+mxXepbO2P9dtbAIzMFVt0DFMMBiE6oSZwCaYqgh0BCqiY8zGiA4tYvrBT1JG+FnKWN58MLHbI/3OMEc2GZpYsUhPm8gjra1od4alWGTpeZCHamekyYr64PjvpLSQTUs8mGPzofj7b3Lzz5nrUIw8l931umfFQvH0pN/teA7zXafXkYunp1cXF+elostoLpsV0vn3c85pELTuh6bjMM6x72NCHNfTdF03DOY4mBBMiOt5NkKj8ZgyxjlPSZKQefs2kGW4uTHLZbi+ptfX0GoRAMYYLGGMkSXLTVOEEKV0Pp9LkiTkkkkuy1a1WkmlxsWie3tLUylG6VhVy+VyvV7XNI1SCgCWZdWXdLvdReVUSsi9fs05R7PZTa+nE+JwTkulRX6Mm81mq9WyLCsUA0C32z0+Ph6NRo/iZJKPx85wOKrXSb/vKArL5QhCnu+rqmoYhu/7Ydue5+m6XiqVfN//0HbmzRteqVgnJ7hQgFKJFotweQmUYrTAtm0AII8sG1p8CYJgIZbyec55ezA4q1avbm7wdArTKWNsMBicnJycn58Ph0PGFvfSMIxCoXB2dlapVD6I05IUzGaAcafdNicTBkAwJgCUUlVVR6NRODAhxHEcTdMajQYAPIrT6fl8TikdDof08d6HEyKEAMDzvNDDGLMs6+7uDgA+zCxJEue83+93Op1utxsKMMa9Xk+W5UajEUbDshfGmOd5GGPOeTqdXlTmnCuKcnV1ValUMMarUymXy5eXl81mM1SGhCN8FAdB4LputVo1TXPV5GKL7fb9/T3nfFV5NddnlTHGlmURQsK4UG+aJkIodNJP+Ezsuu5kMiGEYIzJFwh/QKvTns1mi4Xl83nHcTqdjqIonuf5T8B1Xc55LpcTjo6OZFmuVqu1Wu36adRqNVmWj46OhEQisfvIzpPZ3d1NJBLC/v7+9/+K/f194bv/wP8n/gs8XYXMLosNNwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc24ef14e49e14175001967c15e5f3c2/f13a9/Tasker-(9).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bc24ef14e49e14175001967c15e5f3c2/ba381/Tasker-(9).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc24ef14e49e14175001967c15e5f3c2/772e8/Tasker-(9).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bc24ef14e49e14175001967c15e5f3c2/772e8/Tasker-(9).png",
              "alt": "Tasker - Add a Profile",
              "title": "Tasker - Add a Profile",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Then select the Net Tile.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c5cce67ee9bc46f361ee1ecb3b549258/772e8/Tasker-(10).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAENElEQVQ4y9WS23LaVhSG9Vh9gdruRaYTEhDkxheO62HiF/IbtBeexCDbODcOmHiCE+MAwQkHISEhEQmEpL32QUKnjsBOm2Q600mv+s0/e5a29r/XWtLieD6fzWZyuUe5HJ/P53mez2azuVwu/zWFQuHhw4fZbLZQKPA8vz7APXqcfbT1U+bXXzY2tzY2ft7a2tpYwfP83808z29ubj548ODJkye5XC6TyRQKBS5N9fgxvyKXy/H35L8jk8msM6/vSjPv7Ow8ffp051+wu7v7zUluvfUD7O7ucsVicX9//9mzlb4Pnu3/E8VikTs8PHQWC0ObzKb6Z10zdG021Q1dM3XdnKbStW9RVdWyrMPDQ+7lWQWiZGDhsUMNYGOXijbREZUcqrhUcShQz/e+glKaJMnp6Sn38vTEZOG5Ytc1p2W4vRmqTpyPM9Qy3EvNfa25pgsexYRgei8MKImjk+Nj7kX5mIWxgwARYkMqhMkC4QUCG7ADeI6w6abrzAVzJcNGOIielwXujyPBYKFo2tICZAvJFpIsNHHpxKXqShpiGqLy/aaGmGJj04t+f1HiTo6FJI46728uL2pv37yZG8aSUVkcKrKkSKOxNJKGw9FgEHgsfRyJYr8nj8TQ904EgSuVy0mSjEZS7eKieXNDKAWMx4oyVhRZHo9G0mCYQhlTVHUy0YZDsdfvU8bKgsCVy+U4jjHGmqYhhCilAMAYC4LAsizHcaIo8n0fAJbLJQAYhrFcLpMkKZVKqTmKY8B4Op06jkMIAQCEECHEsizTNAkhCCEAwBgDgGmaGOMwDO/McRQh26aErH5ImnntRwgxxtbXYYwRQrIsS5KkaVqSJEdHR1z55CSZzciHD0yWWbfLRBEQooyJotjpdBqNhiRJdIWu6+fn59VqtdFo/FV2EseAEAYgrktWAV6tgJBj28h18aoLczYbDAaSJImiGEXRfdlJggDUycR2XULTaQJK/eVyouufTdMPAqAUCPE8DyE0Ho9934/jODWXSqUwDB3XJYSkbTMGqkrrddpskrdvodGgzSar10FVieet+weAKIrSngVBCIKg2Wy2Wq12q6XN516vR9pt3/dbV1fd62vf80i7zXo9w3HE4VBRFFVV78oWBCEMw3crLl+/npimPxo5t7ftjx/f39xcv3t33enM2+2lJI2n0w+dzqdPn9rtdhiG5XL5bkhs255MJgvLwkFA+30sy3YUafO5Ydt2GGJJIv2+nySMUsuyfN+/+9qlUikIAgBQVdVeLGC5RN0urlRYtzur141q1bu9JZUK3N4i37fm8/F47LpuEARpz2dnZ0mSBEEQx3G0Ig6C2LJSLRap1nEQ3L2N4/V4VioV7uDgoNFovHr1qvaFi4va5eUXXax1T61Wq1arV1dXBwcH3Pb2dv6H2N7e5vb29oo/xN7eHvfbf+B/av4TwAk92/sWNy8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5cce67ee9bc46f361ee1ecb3b549258/f13a9/Tasker-(10).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c5cce67ee9bc46f361ee1ecb3b549258/ba381/Tasker-(10).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5cce67ee9bc46f361ee1ecb3b549258/772e8/Tasker-(10).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c5cce67ee9bc46f361ee1ecb3b549258/772e8/Tasker-(10).png",
              "alt": "Tasker - Add a Profile",
              "title": "Tasker - Add a Profile",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`We would like to carry out the action as soon as the smartphone connects to a specific Wi-Fi network.`}</li>
    </ol>
    <p>{`To make sure that the action is executed only when we are in our home Wi-Fi, we need to set a value that Tasker can use to detect it. In our case we will simply choose the name of the network (SSID). In our case, the profile will only perform the action if the smart phone connects to a Wi-Fi network called WTec. You can also assign a fixed IP address to your phone via the router, or use the MAC address of your wireless router as an indicator. Then go back to save the profile and add the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Tasker/Motion_Detection_Add_Task/"
      }}>{`previously created task`}</a>{` to the profile.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6a8b8a817f1edc18479a8d6fbec9865d/772e8/Tasker-(11).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAACnUlEQVQ4y+WUbXeTMBiG+f/+GHfsqc6V6Wpdd1a1UrSUAcLpVtuN94SEQBKoB9JlfZlu+tXrPB84IXcS7psnimU7U/3TVP/S738YDAbn5+eqqg4GA9d1f9zjOI7neZeXl4ZheJ5n2/ZsNnNdV9Em3752XoyHve6bt53OK1VVj4+Pe72eruuO4wix67q2bXc6nX6/P5/PTdMcj8ee5ymOY5szwzSvRqPRZDL53DIajS4uLqRYoGmaYRjiROKVghsQQggAEMdxskXUIgcRQtk9GOOyLJXr63m32z09PV0ul2maitmCtAUAACHMsgwhlOc5YyyOY8uyOOeK7/u6rk+n0yAIol22l4AQIoQwxkVRUErv7u4QQkpd1+uWuq7pHoxRdg9vqRqkRFmv12mSLH8uIEjl6NM0M+tGPP5uvBt8fN1TV2FU1mtMec4qWUQWfyhEOatrhfI6KquE1QmvA8KeU2HBgoKXVa1QSkme5whlAGQQouyhcJY1hVCOEclxQXJaEM4oLcuyIM03W7b98ujo/dnZ6vY2juN9u5MkEYFt0sJlSaMoNs0rSmkTlWVZruvKqGTOQghaZM6EEEppEAQYY2XLv4aqqtgufAuR005UhJA0TVELxnj9bBoxAMDzvNVqtVgsgiCQp/gD4gjK4Sh7CvEJjZgQEoYhACA9QFgluwJjTAgpy1LoG7FpmsPhEEIYHbCV1KYx8jwXjcEYa8QAADEjSZK9fpb9KFtK7Ewp5ZxvDNvrhyfd2nEbY+z7vtgNQvh3UUEIb25ufN+/bf/Q6hlsDJNPIifOOX2Mx6NCCIVhGEWROPm2Z4/eYWVLURSN2HEcVVVPTk40TUvTVCz0u6jEpSn2q6qquXpFJ8p5hz+JkG1HxRjbGPbP/I/iX2bsH3VOCxxrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a8b8a817f1edc18479a8d6fbec9865d/f13a9/Tasker-(11).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6a8b8a817f1edc18479a8d6fbec9865d/ba381/Tasker-(11).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a8b8a817f1edc18479a8d6fbec9865d/772e8/Tasker-(11).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6a8b8a817f1edc18479a8d6fbec9865d/772e8/Tasker-(11).png",
              "alt": "Tasker - Add a Profile",
              "title": "Tasker - Add a Profile",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Define here where Tasker should recognize your home network and assign the previously created task to the profile.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      